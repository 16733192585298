import React, { useContext, useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import Container from '../Container';
import Row from '../Container/row';
import Col from '../Container/column';
import ResponsiveContext from '../Responsive/context';

export default function HomepageLogos() {
  const data = useStaticQuery(graphql`
    query HomeLogosQuery {
      wpPage(slug: { eq: "home" }) {
        ...HomeLogos
      }
    }
  `);
  const logos = data.wpPage.homepageLayout.logos;

  const { windowXXL, windowMD, windowSM } = useContext(ResponsiveContext);
  const [limit, setLimit] = useState(1);

  useEffect(() => {
    let colLimit = 1;
    if (windowSM) {
      colLimit = 2;
    }
    if (windowMD) {
      colLimit = 3;
    }
    if (windowXXL) {
      colLimit = 5;
    }
    setLimit(colLimit);
  }, [windowXXL, windowMD, windowSM]);

  // const limit = 5;
  const count = Array.from(
    { length: Math.ceil(logos.length / limit) },
    (e, i) => i,
  );

  return (
    <Container>
      {count.map(i => (
        <Row
          key={i}
          align="center"
          justify="space-evenly"
          style={{ marginTop: i !== 0 && `30px` }}
        >
          {logos.slice(i * limit, (i + 1) * limit).map((logo, x) => {
            const key = `homepageLogo${x}`;

            const { altText, title } = logo.image;
            const image = getImage(logo.image.localFile);

            return (
              <Col
                key={key}
                xl={{ width: `auto` }}
                md={{ width: `33.333%` }}
                sm={{ width: `50%` }}
                style={{ textAlign: `center` }}
              >
                <GatsbyImage
                  image={image}
                  alt={altText !== '' ? altText : title}
                />
              </Col>
            );
          })}
        </Row>
      ))}
    </Container>
  );
}
