import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../layouts';
import BannerCta from '../components/Cta/banner';
import Logos from '../components/Homepage/logos';
import Slider from '../components/Slider';

import '../components/Homepage/style.css';

import loadComponents from '../components/Loadable';

const Intro = loadComponents('homepage');
const HomepageColumns = loadComponents('homepage-columns');

export default class IndexPage extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
    };
  }

  componentDidMount() {
    this.initPage();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  initPage = () => {
    if (!this._isMounted) {
      this._isMounted = true;
      this.setState({ loaded: true });
    }
  };

  render() {
    const { data, location } = this.props;
    const { loaded } = this.state;

    const prevPage = location.state && location.state.prevPage;
    const currentPage = data.wpPage;

    const pageTitle =
      currentPage.seo && currentPage.seo.title
        ? currentPage.seo.title
        : currentPage.title;

    return (
      <Layout
        title={pageTitle}
        seo={currentPage.seo}
        location="/"
        padding="0"
        prevPage={prevPage}
      >
        <Slider />
        <BannerCta />
        <div className="homepageContent">
          <Logos />
          {loaded && (
            <>
              <Intro />
              <HomepageColumns />
            </>
          )}
        </div>
      </Layout>
    );
  }
}

export const HomepageData = graphql`
  query HomeQuery {
    wpPage(slug: { eq: "home" }) {
      title
      ...PageSeo
    }
  }
`;
